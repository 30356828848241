/*==================================
* Author        : "ThemeSine"
* Template Name : CarVilla  HTML Template
* Version       : 1.0
==================================== */

/*==================================
font-family: 'Poppins', sans-serif;
font-family: 'Rufina', serif;
==================================== */


/*=========== TABLE OF CONTENTS ===========
1.  General css (Reset code)
2.  Top-area
3.  Welcome-hero
4.  Model-search
5.  Service
7.  Featured-cars
8.  Client-say
9.  Brand
10. Blog
11. Contact
==========================================*/

/*-------------------------------------
		1.General css (Reset code)
--------------------------------------*/

.ban {
    margin: 0px 0px 0px 20px;
}

.spantitle {
    font-size: 10px;
}

* {
    padding: 0;
    margin: 0;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #818998;
    text-transform: initial;
    margin: 0 auto;
    overflow-x: hidden;
}

a,
a:hover,
a:active,
a:focus {
    display: inline-block;
    text-decoration: none;
    color: #444a57;
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
}


.posted-text {
    font-size: 20px;
}

.posted-title {
    font-size: 24px;
}

img {
    border: none;
    max-width: 100%;
    height: auto;
}

select,
input,
textarea,
button,
.form-control {
    box-shadow: none;
    outline: 0 !important;
}

button {
    background: transparent;
    border: 0;
    font-size: 16px;
    text-transform: capitalize;
}

html,
body {
    height: 100%;
}

[placeholder]:focus::-webkit-input-placeholder {
    -webkit-transition: opacity 0.3s 0.3s ease;
    -moz-transition: opacity 0.3s 0.3s ease;
    -ms-transition: opacity 0.3s 0.3s ease;
    -o-transition: opacity 0.3s 0.3s ease;
    transition: opacity 0.3s 0.3s ease;
    opacity: 0;
}

/*=============Style css=========*/


/*-------------------------------------
        2. Top-area
--------------------------------------*/
nav.navbar.bootsnav {
    background-color: transparent !important;
    border-bottom: transparent !important;
    position: relative !important;
}

nav.navbar.bootsnav.sticky {
    background-color: #000 !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .2) !important;
    /* position: absolute !important;
    top: 0; */
}

.header_bg {
    background-color: #000 !important;
}

/*.navbar-brand*/
.navbar-header a.navbar-brand span {
    display: inline-block;
    color: #0097b2;
    text-transform: capitalize;
}

/* .wrap-sticky nav.navbar.bootsnav.sticky .navbar-header a.navbar-brand, */
/* .wrap-sticky nav.navbar.bootsnav.sticky .navbar-header a.navbar-brand:hover, */
.wrap-sticky nav.navbar.bootsnav.sticky .navbar-header a.navbar-brand:focus {
    padding: 10px !important;
}

.navbar-brand img {
    max-width: 190px;
    height: auto;
}

.d-none {
    display: none !important;
}

.top-info-bar {
    padding-top: 10px;
    border-bottom: 2px solid #828282;
    vertical-align: middle;
    padding-bottom: 10px;
}

.top-info-bar .info-text p {
    font-size: 15px;
    color: #ffffff;
    text-transform: uppercase;
    font-style: italic;
    vertical-align: middle;
}

.info-icon {
    display: flex;
    justify-content: flex-end;
}

.top-info-bar .info-icon a {
    color: #ffffff;
    padding-right: 30px;
    font-size: 25px;
}

.top-info-bar .search-container button {
    padding: 6px 10px;
    background: #ddd;
    font-size: 17px;
    border: none;
    cursor: pointer;
    border-radius: 0px 5px 5px 0px;
}

.top-info-bar input[type=text] {
    padding: 6px;
    font-size: 17px;
    border: none;
    border-radius: 5px 0px 0px 5px;
}

/*.navbar-brand*/

/*.nav li*/
nav.navbar.bootsnav ul.nav>li>a {
    color: #f5f7fa;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -ms-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;
}

nav.navbar.bootsnav ul.nav>li>a {
    padding: 30px 25px;
}

nav.navbar.bootsnav ul.nav>li>a:hover,
nav.navbar.bootsnav ul.nav>li>a:focus {
    color: #0097b2;
}

nav.navbar.bootsnav ul.nav>li.active>a {
    color: #0097b2;
    font-weight: 700;
}

nav.navbar.bootsnav ul.nav>li.dropdown span {
    font-size: 8px;
    margin-left: 15px;
}

/*.nav li*/

/*.menu-ui-design*/

/* .menu-ui-design {
    overflow-y: scroll;
    height: 350px; 
} */

.menu-ui-design::-webkit-scrollbar {
    width: 5px;
}

.menu-ui-design::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 8px #000;
}

.menu-ui-design::-webkit-scrollbar-thumb {
    background-color: #0097b2;
}

/*.menu-ui-design*/

/*.navbar-toggle */
nav.navbar.bootsnav .navbar-toggler {
    position: relative;
    background-color: transparent;
    border: 1px solid #0097b2;
    padding: 10px;
    top: 6px;
}

nav.navbar.bootsnav .navbar-toggler i {
    color: #0097b2;
    padding: 3px 7.5px;
}

/*.navbar-toggle */

/*-------------------------------------
        3.  Welcome-hero
--------------------------------------*/
.welcome-hero {
    position: relative;
    background: url(../images/welcome-hero/banner_euro.jpeg)no-repeat;
    background-position: center;
    background-size: cover;
    /* z-index: 1; */
}

.blog-component .welcome-hero {
    background: url(../images/Blogheader.jpeg)no-repeat;
    background-position: center;
    background-size: cover;
}

.faq-component .welcome-hero {
    background: url(../images/faqbg.jpg)no-repeat;
    background-position: center;
    background-size: cover;
}

/* .eurocharged-license {
    position: relative;
    background: url(../images/Blog-bg.jpg)no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
} */
.about {
    width: 747px
}

.list-style-square {
    list-style: square;
}

.list-style-circle {
    list-style: circle;
}

.custom-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 2rem;

}

.text-tronsform {
    text-transform: none;
}

.text-tronsform:hover {
    text-transform: none;
}

/* .home-blog-card{

    height: 400px;
} */

@media (min-width: 767px) {
    .services-text-block {
        margin-left: -174px;
    }

    .services-block {
        display: flex;
    }
}



@media (max-width: 767px) {
    .services-block {
        display: block !important;
    }

    div.services-text-block {
        width: 100%;
    }

    div.service-image-block {
        width: 100%;
    }
}

.custom-dropzone-class {
    height: 100%
}

.custom-dropzone-class2 {
    height: 75%
}

.drag-drop-text {
    text-align: center;
}

.image-container1 {
    width: 100px;
    text-align: left;
}

.euro-component .welcome-hero {
    background: url(../images/Blog-bg.jpg)no-repeat;
    background-position: center;
    background-size: cover;
    height: 400px;
    /* z-index: 0; */
  
}

.privacy-component .welcome-hero {
    background: url(../images/Blog-bg.jpg)no-repeat;
    height: 380px;
}

.services-component .welcome-hero {
    background: url(../images/servicesbg.jpg)no-repeat;
    background-position: center;
    background-size: cover;
}

.euro-about-component .welcome-hero {
    background: url(../images/Aboutimage.jpeg) no-repeat bottom center;
    background-size: cover;
}


.euro-becomecomponent .welcome-hero {
    background: url(../images/becomeadealer.jpeg)no-repeat;
    background-position: center;
    background-size: cover;
}

.euro-licnesebrnad-component .welcome-hero {
    background: url(../images/eurocharged-license-partner.jpeg)no-repeat;
    background-position: center;
    background-size: cover;
}

.accordion-button:not(.collapsed) {
    background-color: #0097b2 !important;
}

.accordion-button:focus {
    box-shadow: none;
}

.welcome-hero:before,
.blogs-page-section::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 48%);
}

.header-area {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
}

/*.welcome-hero-txt*/
.welcome-hero-txt {
    position: relative;
    text-align: center;
    padding: 200px 0 370px;
}
.about-header-text{
    position: absolute; 
    bottom: 0; 
    float: left; 
    transform: translateX(-50%); 
    font-size: 38px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 45px; 
    text-align: left;

}

.welcome-hero-txt h2 {
    font-size: 42px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 45px;
}

.welcome-hero-txt p {
    font-size: 18px;
    text-transform: initial;
    color: #fff;
    font-weight: 500;
    max-width: 735px;
    margin: 0 auto;
}

.welcome-btn {
    display: inline-block;
    width: 230px;
    height: 60px;
    background: #0097b2;
    color: #fff;
    border-radius: 3px;
    margin-top: 55px;
    -webkit-transition: .3s linear;
    -moz-transition: .3s linear;
    -ms-transition: .3s linear;
    -o-transition: .3s linear;
    transition: .3s linear;
}

.welcome-btn:hover {
    background: #2e8492;
}

/*.welcome-hero-txt*/

/*-------------------------------------
        4. Model-Search
--------------------------------------*/
.model-search-content {
    padding: 25px;
    background: #7a7a7a99;
    box-shadow: 0 10px 40px 0px rgba(38, 40, 64, .2);
    position: absolute;
    width: 100%;
    border-radius: 3px;
}

/*.single-model-search*/
.single-model-search {
    margin-bottom: 30px;
}

.single-model-search:last-child {
    margin-bottom: 0;
}

.single-model-search h2 {
    font-size: 19px !important;
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 20px;
    color: #ffffff;
    text-align: left
}

/*model-select-icon*/
.model-select-icon {
    position: relative;
}

.model-select-icon .css-t3ipsp-control {
    outline: 0 !important;
    box-shadow: none;
    border: 1px solid #f8f8f8;
    background: #f8f8f8;
    min-height: 60px;
}

.model-select-icon select {
    font-size: 16px;
    color: #888f9d;
    text-transform: capitalize;
}

/*model-select-icon*/
.welcome-btn.model-search-btn {
    width: 160px;
    margin-top: 38px;
    margin-left: 53px;
    height: 40px;
}

/*.single-model-search*/

/*-------------------------------------
        5. Service 
--------------------------------------*/
.service {
    position: relative;
    background-color: black;
    display: flex;
    overflow: hidden;
    align-items: center;
    height: 550px;
}

.service:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 70%);
    z-index: 2;
}

.service .service_video {
    z-index: 1;
}

.service .container-fluid {
    width: 100%;
    z-index: 3;
    position: absolute;
    text-align: center;

}

.single-service-item {
    overflow: hidden;
    transition: .3s linear;
}

.single-service-icon .icon-number {
    font-size: 40px;
    font-weight: 700;
    color: #ffffff;
}

.single-service-icon .single-icon {
    font-size: 40px;
    font-weight: 400;
    color: #0097b2;
}

.single-service-item h2 {
    font-size: 28px;
    color: #ffffff;
    text-transform: uppercase;
}

/*-------------------------------------       
        8. blog-section 
--------------------------------------*/
.blog-section {
    padding: 90px 0 80px;
    background: #f8f9fb;
    text-align: center;
}

.Blog-header h2 {
    color: #000000;
    text-transform: uppercase;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
}

.blog-section hr {
    border: none;
    border-top: 3px solid #b4b4b4;
    overflow: visible;
    width: 20%;
    margin: 40px auto 50px auto;
}

.blog-btn {
    font-size: 17px;
    background: #0097b2;
    color: #fff;
    border-radius: 10px;
    text-transform: uppercase;
    padding: 10px 30px;
}

.blog-area .card {
    border-radius: 5px;
    box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
    border: 1px solid #e2e2e2;
}

.home-blog-img {
    height: 250px;
    width: 200px;
}


.blog-area .card-header .blogImage {
    border-radius: 5px;
    width: 100%;
    height: 300px;
}

.blog-area .card-body {
    padding: 15px;
}

.blog-area h5.card-title {
    padding-bottom: 10px;
    font-size: 19px;
    line-height: 1.4;
    color: #000000;
}

.blog-area .card-footer {
    padding: 10px 10px 20px 10px;
}

/*owl carousel*/

/*-------------------------------------
        9. Brand
--------------------------------------*/
.brand-section {
    padding: 80px 0;
    /* width: 100%; */
    background: url(../images/brand_bg.jpeg) repeat;
    position: relative;

}

.brand-section:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 75%);
}

.brand-area {
    position: relative;
}

.brand-section .brand-area img {
    vertical-align: middle;
}

.brand-section hr {
    width: 10%;
    border: 0;
    border-top: 3px solid #ffffff;
    margin: 10px auto 10px auto;
    opacity: 0.9
}

.brand-section .brand-area .splide__slide {
    text-align: center;
}

/*-------------------------------------
        12. Contact
--------------------------------------*/
.footer {
    background: #000000;
    padding-left: 20px;
    padding-right: 20px;
}

.footer-top {
    padding: 80px 0 20px;
}

/*.footer-logo*/
.footer-logo a,
.footer-logo a:hover,
.footer-logo a:focus {
    display: inline-block;
    max-width: 250px;
    height: auto;
}

.single-footer-widget div p {
    font-size: 14px;
    color: #d1d7e9;
    margin: 10px 0 10px;
}

/*.footer-logo*/
.single-footer-widget {
    margin-bottom: 40px;
}

.single-footer-widget h2 {
    font-size: 17px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.single-footer-widget div p {
    display: inherit;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    text-decoration: none;

}

.font-footer:hover {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);

}

.margin-top-min {
    margin-top: -100px;
}

.font-footer {
    font-size: 14px;
    color: #cfd0e3;
    font-weight: 300;
}

.font-footer:hover {
    font-size: 14px;
    color: #cfd0e3;
    font-weight: 300;
}

.single-footer-widget p {
    font-size: 14px;
    color: #cfd0e3;
    font-weight: 300;
    padding-bottom: 13px;
    text-decoration: none;
    color: #cfd0e3;
}

.single-footer-widget p a span {
    text-transform: lowercase;
}

/*.footer-copyright */
.footer-copyright {
    padding: 25px 0;
    text-align: center;
    border-top: 1px solid #0097b2;
}

.footer-copyright p,
.footer-copyright p a {
    color: #a8a9bf;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
}

.footer-social a {
    color: #8c8c8c;
    text-decoration: none;
    height: 25px;
    width: 30px;
}

.footer-social a:hover {
    color: #fff;
}

.footer-social h1 {
    color: #888f9d;
    margin-bottom: 10px;
}

.paymentIconAarea {
    position: relative;
    margin: 20px 0;
}

.paymentIconAarea .payment-item img {
    display: inline-block;
    /* width: 60px; 
    height: 42px;
    border-radius: 5px;
    margin: 0px 10px 10px 10px; */
}

.payment-item .paymasterImg {
    height: 50px;
    width: 270px;
}

/*.footer-copyright */

/*===============================
    Scroll Top
===============================*/
#scroll-Top .return-to-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    display: none;
    width: 40px;
    line-height: 40px;
    height: 40px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
    background: #0097b2;
    border: 1px solid #0097b2;
    border-radius: 50%;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    z-index: 2;
}

#scroll-Top .return-to-top:hover {
    background: #fff;
    color: #0097b2;
    border: 1px solid #0097b2;
}

#scroll-Top .return-to-top i {
    position: relative;
    bottom: 0;

}

#scroll-Top .return-to-top i {
    position: relative;
    animation-name: example;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-duration: 1s;
}

.blogs-page-section {
    /* padding: 180px 0; */
    /* background: url(../images/R.jpg) center center/cover; */
    background: url(../images/Blogheader.jpeg) no-repeat;
    block-size: auto;
    background-position: center;
    background-size: cover;
    z-index: 1;
}

.single-page-blogs img {
    display: block;
    margin: 0 auto;
    height: 600px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    position: relative;

}

.single-page-blogs h1 {
    position: relative;
    text-align: center;
    margin-top: -280px;
}

.single-page-blog-section-one,
.blog-content {
    height: auto;
    position: relative;
}

.first-blog-content {
    padding: 180px 0;
    background: url(../images/R.jpg) center;
    position: relative;
    text-align: center;
    block-size: auto;
    width: 50%;
}

.card-header-blog {
    position: relative;
}

.overlay-body {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    font-size: small;
}

.blog-component .blogImage {
    width: 100%;
    height: 400px;
    transition: transform 0.9s ease;
}

.img-blog {
    height: 390px;
}

.img-blog-under {
    height: 390px;
}

.image-container:hover .blogImage {
    cursor: pointer;
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1);
}

.blog-component .image-container::before {
    background-image: -webkit-linear-gradient(#0097b2, #0e0e0e);
    background-image: -moz-linear-gradient(#0097b2, #0e0e0e);
    background-image: -ms-linear-gradient(#0097b2, #0e0e0e);
    background-image: linear-gradient(#0097b2, #0e0e0e);
    opacity: .8;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 2;
    opacity: .3;
    right: 0;
}

.blog-component .overlay {
    top: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blog-component .blog-content {
    z-index: 2;
}

.btn-read-more {
    position: relative;
}

.btn-read-more-under {
    position: relative;
    margin-top: 50px;
}

.tuning-price-color {
    color: #16d4f6 !important;
}

.price-block {
    content: "";
    width: 0;
    height: 0;
    border-color: transparent transparent transparent #0097b2;
    border-style: solid;
    border-width: 23px 0px 23px 15px;
}

.single-blog-component .blog-info .blog-author span:not(:first-child):before,
.blog-component .blog-author span:not(:first-child):before {
    position: absolute;
    content: "";
    height: 14px;
    width: 1px;
    background-color: #fdfdfd;
    left: 0;
    top: 5px;
}

.blog-component .blog-author span:not(:first-child):before {
    top: 10px;
}

.blog-component.app-padding {
    padding-top: 140px;
}

@keyframes example {
    0% {
        bottom: 0px;
    }

    100% {
        bottom: 7px;
    }
}

.dropdown-menu ul {
    font-size: 14px !important;
}

.navbar-nav .dropdown-menu li:hover {
    background-color: #0097b2 !important;
}

.navbar-nav .dropdown-menu a {
    font-size: 12px !important;
}

.red-star::placeholder::before {
    content: "* ";
    color: red;
}

.red-star::-webkit-input-placeholder {
    font-size: 15px;
}

.red-star:-moz-placeholder {
    font-size: 15px;
}

.red-star::-moz-placeholder {
    font-size: 15px;
}

.latest-blog {
    margin-right: 30px;
    width: 1300px;
    padding: 20px;
    padding-top: 0;
    align-items: flex-start;
}

.order-logo-img {
    width: 200px;
    height: 75px;
}

#button-remove {
    background-color: red;
}

.fixed-dyno-height-img {
    height: 200px;
    object-fit: cover;

}



@media (max-width: 991px) and (min-width: 768px) {
    .response-login-btns {
        flex-direction: row !important;
        gap: 15px;
    }

}

@media (max-width: 767px) {
    .navbar-nav .dropdown-menu li {
        background-color: transparent !important;
        /* Reset background color for submenu items */
    }

    .navbar-nav .dropdown-menu a {
        color: #fff !important;
        /* Set text color for mobile view */
    }

    .second-dropdown {
        margin-top: 20px !important;
    }

    .map-padding {
        margin-top: 20px !important;
    }
}

.map-section {
    width: 100%;
    height: 100%;
}

#brand h2 {
    text-shadow: #0097b2 8px -2px 7px;
}

.dropdown ul li {
    display: block;
}

.dealer_section {
    background: url(../images/brand_bg.jpeg)repeat;
    z-index: 1;
    overflow: hidden;
    position: relative;
}

.dealer_section:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 70%);
}

.dealer_zip_section {
    padding: 60px 100px 0px !important;
}

.dealer_map_section {
    margin-bottom: -6px;
    margin-left: -15px;
    margin-right: -15px;
    height: 539.5px;
}

.dealer_map_section iframe {
    width: 100%;
}

.dealer-header h2 {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 36px;
    font-weight: 700;
}

.dealer-header hr {
    border: none;
    border-top: 3px solid #b4b4b4;
    overflow: visible;
    width: 20%;
}

.dealer_zip_section form {
    margin-top: 40px;
}

.dealer_zip_section form .form-control {
    margin-bottom: 30px;
}

.user-dropdown .fa.fa-user:after,
.user-welcome-message:after {
    font-family: 'FontAwesome';
    content: "\f0d7";
    margin-left: 5px;
    margin-top: 2px;
}

.user-dropdown .dropdown-menu ul {
    padding: 15px 0 0 0;
}

.user-dropdown .dropdown-menu {
    padding: 10px;
}

.user-dropdown div.dropdown-menu {
    width: 250px;
}

.user-dropdown ul.dropdown-menu li:not(:last-child) {
    margin-bottom: 10px;
}

.user-dropdown .dropdown-menu li>a {
    font-size: 14px;
    width: 100%;
    padding: 5px;
    border-radius: 4px;
}

/* Shop Page */
.shop.app-padding {
    background: #151515 url(../images/tuning-bg.jpg) no-repeat center top;
    background-size: 100% 100%;
}

.app-main-content {
    min-height: 800px;
}

.app-padding {
    padding-top: 180px;
    padding-bottom: 80px;
}


.euro-licnesebrnad-padding {
    padding-top: 140px;
    padding-bottom: 100px;
}

.becomedealer-padding {
    padding-top: 70px;
    padding-bottom: 80px;
}

.contact-padding {
    padding-top: 80px;

}

.about-padding {
    padding-top: 140px;
    padding-bottom: 80px;
}

#mobile_shop_filters .nav.navbar-nav {
    padding-top: 15px;
}

#mobile_shop_filters .nav.navbar-nav li {
    margin-bottom: 10px;
}

.shop__sidebar .navbar-nav>li {
    float: left;
    width: 100%;
}

.shop__sidebar .card {
    padding-bottom: 20px;
}

.card-heading {
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    padding-bottom: 10px;
}

.mobile_filter {
    color: #fff !important;
}

.product-grid {
    border-radius: 5px;
    text-align: center;
    border: 1px solid #eee;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    margin-bottom: 30px;
}

.product-grid:hover {
    box-shadow: 0 5px 18px rgba(0, 0, 0, 0.3);
}

.product-grid .product-image {
    position: relative;
}

.product-grid .product-image a.image {
    display: block;
}

.product-grid .product-image img {
    width: 100%;
    height: 350px;
    border-radius: 5px 5px 0px 0px;
}

.product-grid .product-content {
    padding: 20px;
}

.product-grid .title a {
    color: #0097b2;
    font-size: 20px;
    text-transform: capitalize;
    margin: 0 0 7px;
}

.product-grid .title a:hover {
    color: #036e81;
}

.product-grid .price {
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 0.005em;
    margin-bottom: 10px;
    font-weight: 500;
}

.product-grid .product-button-group {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
}

.product-grid .product-button-group button {
    font-size: 14px;
    padding: 15px;
}

.product-grid .add-to-cart,
.single-product-button-group .add-to-cart,
.add-to-cart {
    color: var(--text-color);
    background: #f4f4f4;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 15px;
    display: inline-block;
    transition: all 0.25s ease 0s;
}

.product-grid .add-to-cart i,
.single-product-button-group .add-to-cart i,
.add-to-cart i {
    margin-right: 8px;
    display: none;
    visibility: hidden;
    transition: all 0.25s ease 0s;
}

.product-grid:hover .add-to-cart,
.single-product-button-group .add-to-cart:hover,
.add-to-cart:hover {
    color: #fff;
    background: #000;
    font-size: 14px;
}

.product-grid:hover .add-to-cart i,
.single-product-button-group .add-to-cart:hover i,
.add-to-cart:hover i {
    display: inline-block;
    visibility: visible;
}

.filter-range-wrap .range-slider .price-input {
    position: relative;
}

.filter-range-wrap .range-slider .price-input p {
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 10px;
}

.filter-range-wrap .range-slider .price-input input {
    font-size: 16px;
    color: #ffffff;
    max-width: 20%;
}

#minamount,
#maxamount {
    text-align: center;
    margin-right: 0;
}

.filter-range-wrap .range-slider .price-input input:nth-child(1) {
    margin-right: 28px;
}

.filter-range-wrap .price-range {
    border-radius: 0;
    margin-bottom: 25px;
}

.filter-range-wrap .price-range.ui-widget-content {
    border: none;
    background: rgba(0, 0, 0, 0.1);
    height: 5px;
}

.filter-range-wrap .price-range.ui-widget-content .ui-slider-handle {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background: #0097b2;
    border: none;
    outline: none;
    cursor: pointer;
}

.filter-range-wrap .price-range.ui-widget-content :nth-child(2) {
    margin-left: -1px;
}

.filter-range-wrap .price-range.ui-widget-content.mobile_filter {
    background: #fff;
}

.filter-range-wrap .price-range .ui-slider-range {
    background: #0097b2;
    border-radius: 0;
}

.sidebar__filter {
    position: relative;
    margin-bottom: 40px;
}

.sidebar__filter .section-title {
    margin-bottom: 20px;
}

.sidebar__filter .section-title h4 {
    font-weight: 500;
    color: #ffffff;
    font-size: 18px;
}

.filter_button {
    padding-bottom: 50px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.filter_button .btn_filter,
.product-button-group .btn_filter {
    background-color: #0097b2;
    color: #ffffff;
    font-weight: 500;
    padding: 10px 30px;
    cursor: pointer;
}

.filter_button .btn-secondary {
    background-color: #dbd8d8;
    color: #000;
    font-weight: 500;
    padding: 10px 30px;
}

.filter_button .btn_filter:hover {
    background-color: #2e8492;
}

.product-button-group .btn_filter:hover {
    border: 1px solid #ccc;
    color: #000000;
}

.navbar-header .fa-filter {
    background-color: transparent;
    border: 1px solid #0097b2;
    border-radius: 4px;
    color: #0097b2;
    padding: 14.9px 10px;
}

.shop_search {
    padding: 0px 20px 35px;
}

.shop_search input {
    all: unset;
    font-size: 16px;
    height: 100%;
    width: 100%;
    padding: 6px 10px;
    border-bottom: 1px solid #c6c6c6;
}

.shop_search input::placeholder {
    color: #828999;
    opacity: 0.7;
}

.shop_search button {
    padding: 6px 10px;
    font-size: 17px;
    border: none;
    cursor: pointer;
}

.shop_search .form-control:focus {
    border-color: #66afe9;
    box-shadow: none;
}

#mobile_shop_filters .price-input {
    display: flex;
}

#mobile_shop_filters .amount-parent {
    flex: 1;
    display: flex;
    gap: 10px;
    align-items: center;
}

.filter-range-wrap .range-slider .price-input .price-hash {
    color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
}

.login-component .panel {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.register-container .register,
.otp-validation,
.forgot-password-component .forgot,
.reset-password-component .reset-password {
    border-radius: 6px;
}

.register-container .panel,
.forgot-password-component .panel,
.reset-password-component .panel {
    border: unset;
}

.login-component .panel-body {
    padding: 30px;
}

.login-component .buttons,
.forgot-password-component .buttons,
.reset-password-component .buttons {
    gap: 10px
}

.register-container {
    /* z-index: 100; */
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    height: 100%;
    overflow: hidden;
    transition: all 1s;
}

.register-container.close {
    top: 100%;
    height: 0;
    z-index: -1;
}

.user-welcome-message {
    font-size: 20px;
    padding-right: 30px;
}

.user-welcome-message.mobile-view {
    font-size: 14px;
}

.user-dropdown .dropdown-menu>li>a.btn-success:hover {
    background-color: #5cb85c;
}

.user-settings-component .nav-tabs li a {
    font-size: 16px;
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.h-100vh {
    height: 100vh !important;
}

.d-flex {
    display: flex !important;
}

.btn-register {
    background-color: #0a798d !important;
    border-color: #0a798d !important;
    color: #fff !important;
}

.btn-register:hover {
    background-color: #055b6b !important;
}

.btn-dark-info {
    background-color: #0097b2 !important;
    border-color: #0097b2 !important;
    color: #fff !important;
}

.max {
    width: 100%;
    height: 150px;
}

.btn-dark-info:hover {
    background-color: #036e81 !important;
}

.bg-cyan {
    background: #0097b2 !important;
}

.text-dark-info {
    color: #0097b2 !important;
}

.border-dark-info {
    border-color: #0a798d !important;
}

.flex-1 {
    flex: 1 !important;
}

.align-center {
    align-items: center !important;
}

.justify-center {
    justify-content: center !important;
}

.text-white {
    color: #fff !important;
}

.text-black {
    color: #000 !important;
}

.manage-addresses .border-dashed {
    border-style: dashed !important;
}

.manage-addresses .addresses .address-data {
    height: 350px;
}

.paypal-buttons.paypal-buttons-context-iframe {
    max-width: 250px;
    z-index: 1;
}

.paypal-please-wait {
    width: 250px !important;
    height: 40px !important;
    left: 0 !important;
    top: -24px !important;
    border-radius: 4px;
    z-index: 100 !important;
}

/* toast component css */

.notification-container {
    position: fixed;
    font-size: 14px;
    box-sizing: border-box;
    z-index: 999999;
}

.notification-container button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: 0;
    color: #fff;
    outline: none;
    text-shadow: 0 1px 0#fff;
    opacity: .8;
    line-height: 1;
    font-size: 16px;
    font-weight: 700;
}

.top-right {
    top: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
}

.bottom-right {
    bottom: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
}

.top-left {
    top: 12px;
    left: 12px;
    transition: transform .6s ease-in;
    animation: toast-in-left .7s;
}

.bottom-left {
    bottom: 12px;
    left: 12px;
    transition: transform .6s ease-in;
    animation: toast-in-left .7s;
}

.notification {
    position: relative;
    display: flex;
    width: 360px;
    align-items: center;
    margin-bottom: 16px;
    padding: 16px;
    background: #fff;
    background-position: 15px;
    background-repeat: no-repeat;
    transition: .3s ease;
    pointer-events: auto;
    border-radius: 3px;
    box-shadow: 0 0 10px #999;
    color: #fff;
    opacity: .9;
    overflow: hidden;
}

.notification:hover {
    box-shadow: 0 0 12px #fff;
    opacity: 1;
    cursor: pointer
}

.notification .notification-image {
    margin-right: 12px;
}

.notification .notification-image img {
    width: 24px;
    height: 24px;
}

.notification .notification-contents {
    flex: 1;
    min-width: 0;
}

.notification .notification-contents .notification-message {
    font-size: 14px;
    color: #fff;
}

.notification-title {
    margin-bottom: 4px;
    font-weight: 600;
}

@keyframes toast-in-right {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes toast-in-left {
    from {
        transform: translateX(-100%);

    }

    to {
        transform: translateX(0);
    }
}

.productMsg p {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: #000;
}

.productClear:nth-child(3n+1) {
    clear: both;
}

.product-image-container img {
    height: 250px !important;
    max-height: 250px !important;
}

.chiptunning-overflow {
    height: auto;
    max-height: 400px;
    overflow-x: auto;
}

.chiptunning .chiptunning-data ul:not(.nav-tabs) li {
    display: block;
    padding: 10px 0;
    border-bottom: 1px dotted #fff;
}

.chiptunning .chiptunning-data a img {
    width: 120px;
}

.chiptunning .card .card-body.chiptunning-data {
    background: url(../images/textile_texture_bg.gif) repeat center top;
}

.chiptunning .card-header {
    /* background: url(../images/sub_bg.png) repeat center top; */
    background: #121212;
    box-shadow: 0 1px 2px #000;
}

.chiptunning .card-header img {
    /* height: 80px; */
    max-width: 100px;
    max-height: 60px;
    width: auto;
    height: auto;
}

.chiptunning .vehicle-tabs {
    background: url(../images/sub_bg.png) repeat center center;
}

.chiptunning .vehicle-tabs li img {
    height: 20px;
}

.chiptunning.app-padding.bg-image {
    background: #151515 url(../images/brand_bg.jpeg) no-repeat center top;
    background-size: 100% 100%;
}

.chiptunning.app-padding.bg-image,
.bg-color-dark {
    background: #151515 url(../images/brand_bg.jpeg) repeat center top;
    /* background-size: 100% 100%; */
    position: relative;
}

.chiptunning.app-padding.bg-image:before,
.bg-color-dark:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 75%);
}

.chiptunning-data .nav-tabs .nav-link:hover {
    border-color: #0a798d !important;
}

.breadcrumb__links a,
.breadcrumb__links span {
    background-color: #2c3034;
    position: relative;
    float: left;
    display: inline-block;
    font-family: 'Titillium Web', Arial, Helvetica, sans-serif;
    height: 54px;
    font-size: 20px;
    line-height: 52px;
    margin: 0;
    padding-right: 20px;
    color: #fff;
    text-align: left;
    font-weight: 300;
    vertical-align: middle;
}

.breadcrumb__links .arrow-icon {
    padding-left: 20px;
    color: #ffffff;
    font-size: 20px;
    vertical-align: middle;
}

.addon-products-container .productImg {
    height: 160px;
    aspect-ratio: auto 160 / 160;
    width: 100%;
}

.model-image {
    max-width: 100% !important;
    max-height: 100px;
    height: 90px;
    object-fit: contain;
}

.tuning-table-cell-first>div {
    width: calc(100% - 15px);
}

.table-arrow {
    content: "";
    width: 0;
    height: 0;
    border-color: transparent transparent transparent #0097b2;
    border-style: solid;
    border-width: 20px 0px 20px 15px;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;

}

::-webkit-scrollbar-thumb {
    background-color: #0097b2;
}

.cart-icon span {
    padding: 0px 5px 4px 5px;
    margin-top: 3px;
}

.cart_product_img {
    max-width: 180px !important;
    height: 180px !important;
}

.tableheight {
    height: auto;
    max-height: 500px;
    overflow: auto;
}

.tableheight thead {
    top: -1px !important;
}

.buy_siderbar {
    background-color: #fafafa;
    padding-top: 50px;
    padding-left: 40px !important;
    padding-right: 80px !important;
    padding-bottom: 40px;
    border-left: 1px solid #e6e6e6;
}

.buy_app_padding {
    padding-top: 140px;
}

.buy_siderbar .table {
    --bs-table-bg: #fff0;
}

.order_summary_section_product_list {
    overflow-y: auto;
    max-height: 300px;
    height: auto;
    padding: 0;
}

.order_summary_section_product_list .table {
    margin-top: 10px;
}

/* Handle */
.order_summary_section_product_list::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.product .product_image .product_thumbnail_quantity {
    font-size: 14px;
    font-weight: 500;
    border-radius: 50%;
    background-color: rgb(0, 151, 178);
    color: #fff;
    position: relative;
    right: -45px;
    top: -70px;
    display: block;
    width: 25px;
    text-align: center;
    height: 25px;
    padding-top: 2px;
}

.product .product_image .product_thumbnail_wrapper img.product_thumbnail_image {
    max-width: 60px;
    height: 60px;
    border: 1px solid #e6e6e6;
    object-fit: cover;
}

.order_summary_section_payment_lines tbody {
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
}

.ship_address_section {
    padding-top: 50px;
    padding-bottom: 40px;
}

.manage-addresses .address-data hr {
    border: 1px solid #0097b2;
    width: 20%;
    margin-top: 5px;
    opacity: 1;
}

.buy_page_title {
    background-color: #0097b2;
}

.thankyou_mail_img {
    height: 250px;
    width: 250px;
}

.thankyou_title {
    font-size: 60px;
    font-family: cursive;
    font-weight: 600;
}

.splide__arrow svg {
    fill: #0097b2 !important;
}

.locations-component .location_zip_section .card-body {
    max-height: 400px;
    height: 400px;
    overflow: scroll;
}

.location-disabled {
    transform: rotate(55deg);
    position: absolute;
    top: -2px;
    left: -12px;
    border-width: 2px;
    width: 30px;
    height: 16px;
    border-color: #ffffff;
}

.location_zip_section .search-locations>div {
    border-color: #585757 !important;
}

.searched-locations {
    height: auto;
    max-height: 250px;
    overflow: auto;
    left: 16px;
    right: 16px;
    z-index: 1000;
    margin-top: 1px;
    box-shadow: 0px -1px 5px #888;
}

.searched-locations::-webkit-scrollbar {
    display: none;
}

.searched-locations li:hover {
    background-color: #0097b2;
}

.custom-light-outline {
    border: 1 px solid black;
    background-color: red;
}

.tuning-images ul.splide__list {
    height: 300px;
}

@media only screen and (max-width:990px) {
    .product-grid {
        margin-bottom: 30px;
    }

    .buy_siderbar {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }

    .ship_address_section {
        padding-top: 80px;
        /* padding-left: 40px !important;
        padding-right: 40px !important; */
    }

    .order_summary_section_product_list {
        overflow-y: auto;
        max-height: 200px;
        height: auto;
        padding: 0;
    }

    .thankyou_mail_img {
        height: 200px;
        width: 200px;
    }

    .thankyou_title {
        font-size: 35px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .productClear:nth-child(3n+1) {
        clear: none;
    }

    .buy_siderbar {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }

    .ship_address_section {
        padding-top: 50px;
    }

    .order_summary_section_product_list {
        overflow-y: auto;
        max-height: 250px;
        height: auto;
        padding: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .filter-range-wrap .range-slider .price-input input {
        max-width: 30%;
    }

    .filter-range-wrap .range-slider .price-input {
        margin-bottom: 20px;
    }

    .sidebar__filter a {
        position: relative;
        bottom: 0;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .sidebar__filter a {
        padding: 5px 15px 5px 15px;
    }
}

@media (max-width: 992px) {

    #minamount,
    #maxamount {
        background: transparent;
        color: #fff;
    }

    .filter-range-wrap .range-slider .price-input:after {
        background: #fff;
    }

    .shop_sidebar_component,
    .navbar-header .fa-filter {
        /* float: right;
        position: relative;
        padding: 10px;
        top: 6px; */
        margin-top: 10px;
    }
}

@media (max-width: 992px) {
    .mobile_shop_filters.navbar-collapse {
        position: fixed;
        top: 160px;
        left: 0;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        height: 100% !important;
        margin-top: 0 !important;
    }

    .mobile_shop_filters.navbar-collapse.collapsing {
        height: 100% !important;
        left: -100%;
        transition: height 0s ease;
    }

    .mobile_shop_filters.navbar-collapse.show {
        height: 100% !important;
        left: 0;
        transition: left 400ms ease-in-out;
    }

    .navbar-collapse.mobile_shop_filters {
        right: 0;
        transition: right 400ms ease-in-out;
    }
}

@media (max-width: 576px) {
    .mobile_shop_filters.navbar-collapse {
        width: calc(100% - 60px);
    }

    .blog-component .blogImage {
        height: auto;
    }
}

.readmore:hover {
    background-color: #000 !important;
    font-size: 1rem;
}

.cont-info {
    font-size: .9rem;
}

.cont-info a {
    font-size: .9rem;
}

.map {
    width: 100%;
    height: 100vh;
}

.map-btn a:hover {
    font-size: 1rem;
}

.leaflet-control-attribution {
    display: none;
}

.leaflet-popup-content {
    font-size: 11px !important;
}

.leaflet-container {
    z-index: 1;
}