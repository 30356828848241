@media screen and (max-width:1680px) {}

/*max-width:1680px*/
@media screen and (max-width:1440px) {
	.welcome-hero-txt {
		padding: 268px 0px 122px;
	}

	.model-search-content {
		position: relative;
		bottom: 0;
		top: 60px;
	}
}

/*max-width:1440px*/

@media screen and (max-width:1199px) {
	.welcome-hero-txt h2 {
		font-size: 40px;
	}

	.welcome-btn.model-search-btn {
		margin-left: 28px;
	}
}

/*max-width:1199px*/

@media screen and (max-width:1024px) {}

/*max-width:1024px*/

@media screen and (max-width:1008px) {}

/*max-width:1008px*/

@media screen and (max-width:992px) {
	nav.navbar.bootsnav .navbar-collapse.collapse.show {
		padding-bottom: 30px;
		background-color: #000;
		margin-top: 5px;
		overflow-x: hidden;
	}

	nav.navbar.bootsnav {
		background-color: #000 !important;
		/* height: 160px; */
	}

	.wrap-sticky nav.navbar.bootsnav.sticked ul.nav>li>a {
		padding: 18px 0px;
	}

	nav.navbar.bootsnav .navbar-brand {
		padding: 10px;
	}

	nav.navbar.bootsnav ul.nav>li>a {
		padding: 15px 0px;
		border: transparent;
	}

	.top-info-bar .info-icon a {
		padding-right: 20px;
	}

	#keywordSearch {
		width: calc(100% - 50px);
	}

	.dealer_zip_section {
		padding: 80px 40px 0px;
	}

	.shop nav.navbar.bootsnav {
		background-color: #0000 !important;
		height: auto;
	}

	.app-padding {
		padding-top: 150px;
		padding-bottom: 80px;
	}
}

/*max-width:992px*/

@media screen and (max-width:991px) {
	.welcome-hero-txt h2 {
		line-height: 1.6;
	}

	.model-search-content .col-md-12 {
		padding: 0 40px;
	}

	.single-model-search:last-child {
		margin-bottom: 30px;
	}

	.welcome-btn.model-search-btn {
		margin: 0;
	}

	.app-padding {
		padding-top: 200px;
		padding-bottom: 80px;
	}
}

/*max-width:991px*/

@media screen and (max-width:768px) {}

/*max-width:768px*/

@media screen and (max-width:767px) {
	.single-service-item {
		max-width: 350px;
		margin: 30px auto;
	}

	.footer-social {
		text-align: left;
	}

	.footer-social a i {
		margin-right: 16px;
		margin-left: 0;
	}

	.hm-foot-email {
		max-width: 300px;
	}

	.login-component .register {
		border-bottom-right-radius: 6px;
		border-bottom-left-radius: 6px;
	}

	.app-padding {
		padding-top: 220px;
		padding-bottom: 80px;
	}
}

/*max-width:767px*/

@media screen and (max-width: 660px) {}

/*max-width:660px*/

@media screen and (max-width: 640px) {
	.single-testimonial-box {
		max-width: 350px;
		margin: 0 auto;
	}
}

/*max-width:640px*/

@media screen and (max-width: 580px) {}

/*max-width:580px*/

@media screen and (max-width: 540px) {}

/*max-width:540px*/

@media screen and (max-width: 480px) {
	.welcome-hero h2 {
		font-size: 30px;
	}

	.single-model-search h2 {
		font-size: 16px;
	}

	.tuning-table-cell-first>div>p {
		font-size: 12px;
	}
}

/*max-width:480px*/

@media screen and (max-width: 440px) {
	.welcome-hero-txt h2 {
		font-size: 24px;
		line-height: 1.5;
	}

	.user-settings-component .userSettings .nav-tabs li a.nav-link {
		font-size: 12px;
	}
}

/*max-width:400px*/

@media screen and (max-width: 399px) {

	.single-service-item h2 a {
		line-height: 1.5;
	}

	.section-header h2 {
		font-size: 30px;
	}

	.top-info-bar input[type=text] {
		width: 200px;
	}

	.top-info-bar .info-icon a {
		padding-right: 18px;
	}

	.top-info-bar .info-icon a {
		font-size: 22px;
	}

	.manage-addresses .addresses .address-data {
		height: 400px;
	}
}

/*max-width:399px*/

@media screen and (max-width:350px) {
	nav.navbar.bootsnav .navbar-toggle {
		margin-right: -25px;
		left: -10px;
	}

	nav.navbar.bootsnav .navbar-brand {
		padding: 10x 0;
		font-size: 20px;
	}

	.wrap-sticky nav.navbar.bootsnav.sticked .navbar-header a.navbar-brand,
	.wrap-sticky nav.navbar.bootsnav.sticked .navbar-header a.navbar-brand:hover,
	.wrap-sticky nav.navbar.bootsnav.sticked .navbar-header a.navbar-brand:focus {
		padding: 25px 0px;
	}

	.top-info-bar input[type=text] {
		width: 190px;
	}
}

/*max-width:350px*/

@media screen and (max-width: 402px) {
	.top-info-bar input[type=text] {
		width: 215px;
	}
}

/*max-width:402px*/

@media screen and (max-width: 411px) {
	.top-info-bar input[type=text] {
		width: 165px;
	}
}

/*max-width:411px*/

@media screen and (min-width: 768px) {
	.login-component .register {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}
}

@media screen and (min-width: 768px) and (max-width: 992px) {
	.manage-addresses .addresses .address-data {
		height: 350px;
	}
}

@media screen and (min-width: 992px) {

	.navbar-nav .dropdown:hover>.dropdown-menu,
	.info-icon .dropdown:hover>.dropdown-menu {
		display: block;
	}
}